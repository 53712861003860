<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'manage_clients' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt" routerLink="/clients/new" [attr.uk-tooltip]="'Dodaj klienta'"><span uk-icon="plus"></span></button>
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
             [attr.uk-tooltip]="'Resetuj filtry'"
             (click)="refreshFilters()"
                  [disabled]="isButtonDisabled"
          >
            <span uk-icon="refresh"></span>
          </button>
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">

                <div class="uk-grid uk-margin-bottom">
                  <div class="uk-width-1-4@m">
                        <label>&nbsp;</label>
                      <label>Wybierz listę:</label>
                        <select required class="uk-input" [(ngModel)]="selectedList" (ngModelChange)="saveCriteria()">
                          <option value="all">{{'all_clients' | translate}}</option>
                          <option value="deleted">{{'deleted_clients' | translate}}</option>
                          <option value="last_month">Przesyłki w ciągu pop. miesiąca</option>
                          <option value="volume_growing">Wzrost l. przesyłek m do m</option>
                          <option value="volume_shrinking">Spadek l. przesyłek m do m</option>
                          <option value="missing_price_list">Brakujące cenniki</option>
                        </select>
                  </div>
                  <div class="uk-width-1-6@m">
                    <label>&nbsp;</label>
                    <label>Nazwa Emag / Alias</label>
                    <input class="uk-input" [(ngModel)]="criteria.client_alias_or_emag_name" (ngModelChange)="saveCriteria()">
                  </div>
                  <div class="uk-width-1-6@m">
                    <label>&nbsp;</label>
                    <label>Wybierz wolumen:</label>
                    <select required class="uk-input" [(ngModel)]="criteria.shipment_count_volume" (ngModelChange)="saveCriteria()">
                      <option value=""></option>
                      <option value="A">{{'shipment_count_volume_A'|translate}}</option>
                      <option value="B">{{'shipment_count_volume_B'|translate}}</option>
                      <option value="C">{{'shipment_count_volume_C'|translate}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-6@m">
                    <label title="Ilość przesyłek w poprzednim miesiącu">I. przesyłek w pop. msc.:</label>
                    <select required class="uk-input" [(ngModel)]="criteria.last_mont_shipment_count_by_volume" (ngModelChange)="saveCriteria()">
                      <option value=""></option>
                      <option value="A">{{'shipment_count_volume_A'|translate}}</option>
                      <option value="B">{{'shipment_count_volume_B'|translate}}</option>
                      <option value="C">{{'shipment_count_volume_C'|translate}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-6@m">
                    <label>&nbsp;</label>
                    <button class="uk-button uk-button-primary uk-width-1-1"
                            (click)="runFilters()"
                            [disabled]="isButtonDisabled"
                    >{{ 'filter' | translate }}</button>
                  </div>
                </div>
                <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="client-list">
                    <thead>
                    <tr>
                        <th>{{'id' | translate}}</th>
                        <th>{{'name' | translate}}</th>
                        <th>{{'country' | translate}}</th>
                        <th>{{'city' | translate}}</th>
                        <th>{{'post_code' | translate}}</th>
                        <th>{{'street' | translate}}</th>
                        <th>{{'address' | translate}}</th>
                        <th>{{'phone_prefix_1' | translate}}</th>
                        <th>{{'phone_number' | translate}}</th>
                        <th>{{'email_verified_at' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="clients?.length > 0">
                    <tr *ngFor="let client of clients" [routerLink]=" '/client/' + client.id ">
                        <td>{{ client.id }}</td>
                        <td>{{ client.name }}</td>
                        <td>{{ client.country }}</td>
                        <td>{{ client.city }}</td>
                        <td>{{ client.post_code }}</td>
                        <td>{{ client.street }}</td>
                        <td>{{ client.address }}</td>
                        <td>{{ client.phone_number_prefix }}</td>
                        <td>{{ client.phone_number }}</td>
                        <td>{{ client.email_verified_at }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>

