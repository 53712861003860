<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
      <p class="nav-text">
        Pliki do pobrania
      </p>
    </div>
    <div class="uk-width-1-1 inner-shadow">
      <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
        <div class="uk-grid uk-margin-bottom">
          <div class="uk-width-1-6@m">
            <button class="uk-button uk-button-primary uk-width-1-1"
                    [class.uk-button-primary]="card === 'ido_sell'" (click)="card='ido_sell'">
              <span>Ido Sell</span>
            </button>
          </div>
        </div>
        <div *ngIf="card === 'ido_sell'" class="uk-grid">
          <div class="uk-width-1-1 uk-margin-bottom" (click)="downloadIdoSellPickupPointXml('RO')">
            Automaty paczkowe W Rumunii
          </div>
          <div class="uk-width-1-1 uk-margin-bottom" (click)="downloadIdoSellPickupPointXml('HU')">
            Automaty paczkowe na Węgrzech
          </div>
          <div class="uk-width-1-1 uk-margin-bottom" (click)="downloadIdoSellPickupPointXml('BG')">
            Automaty paczkowe w Bułgarii
          </div>
        </div>
      </div>
    </div>
</div>

