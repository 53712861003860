import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ParcelsService} from "../../../services/parcels/parcels.service";
declare var UIkit: any;

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
    apiUrl: string;

    card = 'ido_sell';

    constructor(
      private parcelService: ParcelsService,
    ) {
      this.apiUrl = environment.api;
    }

    ngOnInit() {}

    downloadIdoSellPickupPointXml(countryIsoCode: string) {
      this.downloadFile('app/v1/locker/ido_sell/' + countryIsoCode)
    }

    downloadFile(url: string) {
      this.parcelService.getPublicFileToken().subscribe(
        (tokenResponse: any)=> {
          window.open(
            this.apiUrl + url + '?_public_token=' + tokenResponse.public_token,
            '_blank',
          );
        }
      );
    }
}
