<script src="../../../services/problems/problems.service.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'create_parcel_from_number' | translate}}
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l">
        <form class="" #parcelForm="ngForm" (ngSubmit)="scan()">
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'parcel_number' | translate}}</label>
                <input name="number"
                       class="uk-input"
                       type="text"
                       required
                       [(ngModel)]="this.form.barcode"
                       #barcodeInput
                >
              </div>
            </div>
            <div class="uk-width-1-3">
              <div *ngIf="null !== this.externalInboundBarcode">
                <label>{{'Polski numer przesyłki' | translate}}</label>
                <input name="externalInboundBarcode"
                       class="uk-input"
                       type="text"
                       required
                       [(ngModel)]="this.externalInboundBarcode"
                       readonly
                       id="externalInboundBarcode"
                >
              </div>
            </div>
            <div class="uk-width-1-3" (click)="showChangeClientModal()">
              <div>
                <label>{{'Klient' | translate}}</label>
                <input name="clientName"
                       class="uk-input"
                       type="text"
                       required
                       [(ngModel)]="this.clientName"
                       readonly
                       (mousedown)="lockAutoFocusOnBarcodeForAWhile()"
                       id="choosedClientId"
                >
              </div>
            </div>
          </div>
          <div class="uk-grid uk-margin-remove-top">
            <div class="uk-width-1-3@m">
              <label>{{'choose_status' | translate}}</label>
              <select name="statusTypeId"
                      required
                      class="uk-input"
                      [(ngModel)]="statusTypeId"
                      (mousedown)="lockAutoFocusOnBarcodeForAWhile()"
              >
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
                </option>
              </select>
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'when' | translate}}</label>
              <input type="datetime-local"
                     autocomplete="none"
                     name="when"
                     [(ngModel)]="form.when"
                     required
                     disabled
                     (mousedown)="lockAutoFocusOnBarcodeForAWhile()"
                     data-uk-timepicker class="uk-input">
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'where' | translate}}</label>
              <input type="text"
                     autocomplete="none"
                     name="where"
                     [(ngModel)]="form.where"
                     required
                     (mousedown)="lockAutoFocusOnBarcodeForAWhile()"
                     class="uk-input">
            </div>
          </div>
          <div class="uk-margin-top">
            <button type="submit" class="uk-button uk-button-primary">
              Przyjmij
            </button>
          </div>
        </form>
      </div>



      <div *ngIf="null !== parcelModel" class="uk-width-1-1@l mt-100" (click)="lockAutoFocusOnBarcodeForAWhile()">
        <hr>
        <p style="text-align: center; font-size: 20px">{{scannedWaybill}}</p>
        <div class="uk-grid">
          <div class="uk-width-1-6@m">
            <label>{{'W. wolumetryczna' | translate}}</label>
            <input class="uk-input" type="number" step="0.01" required disabled [(ngModel)]="parcelModel.volumetric_weight">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'accounted_weight' | translate}}</label>
            <input class="uk-input" type="number" step="0.01" required disabled [(ngModel)]="parcelModel.weight_value">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'weight' | translate}}</label>
            <input class="uk-input" type="text" step="0.01" readonly disabled [(ngModel)]="parcelModel.weight">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'height' | translate}}</label>
            <input class="uk-input" type="text" step="0.01" readonly disabled  [(ngModel)]="parcelModel.height">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'width' | translate}}</label>
            <input class="uk-input" type="text" step="0.01" readonly disabled [(ngModel)]="parcelModel.width">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'length' | translate}}</label>
            <input class="uk-input" type="text" step="0.01" readonly disabled [(ngModel)]="parcelModel.length">
          </div>
        </div>

        <form #parcelDimensionsForm="ngForm" (ngSubmit)="updateDimensions()">
          <div class="uk-grid">
            <div class="uk-width-1-3@m">

            </div>
            <div class="uk-width-1-6@m">
              <label>{{'weight' | translate}}</label>
              <input name="weight" class="uk-input" type="text" (keyup)="lockAutoFocusOnBarcodeForAWhile()" [(ngModel)]="dimensionsForm.weight" #weightInput>
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'height' | translate}}</label>
              <input name="height" class="uk-input" type="text" (keyup)="lockAutoFocusOnBarcodeForAWhile()" [(ngModel)]="dimensionsForm.height">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'width' | translate}}</label>
              <input name="width" class="uk-input" type="text" (keyup)="lockAutoFocusOnBarcodeForAWhile()" [(ngModel)]="dimensionsForm.width">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'length' | translate}}</label>
              <input name="length" class="uk-input" type="text" (keyup)="lockAutoFocusOnBarcodeForAWhile()" [(ngModel)]="dimensionsForm.length">
            </div>
          </div>
          <div class="uk-margin-top">
            <button type="submit" class="uk-button uk-button-primary">
              Zapisz wymiary
            </button>
          </div>
        </form>
      </div>

      <div class="uk-width-1-1@l uk-margin-top">
        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" *ngIf="parcelStatusHistory.length > 0">
          <thead>
          <tr>
            <th>{{'date' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="parcelStatusHistory?.length > 0">
          <tr *ngFor="let status of parcelStatusHistory">
            <td><kbd>{{ status.when }}</kbd></td>
            <td>
              {{ status.displayed_name }}
              <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              <small title="Login użytkownika który nadał status"><br>{{status.email}}</small>
            </td>
            <td>

              <!-- todo fix me? czy usunąc
              <button class="uk-button padding-horizontal-small" style="float: right; margin-top: 0" [disabled]="parcelModel !== null"
                      [attr.uk-tooltip]="'Usuń status'" (click)="onStatus(status.id)"><span
                uk-icon="trash"></span>
              </button> -->

            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>
</div>

<div id="chooseClientModal" class="chooseClientModal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close (click)="setFocusOnBarcodeInput()"></button>

    <div class="uk-modal-header" >
      <h2 class="uk-modal-title" *ngIf="true === clientChosen">{{'Weryfikacja wyboru klienta' | translate}}</h2>
      <h2 class="uk-modal-title" *ngIf="false === clientChosen">{{'Wybierz klienta do przypisania' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid" *ngIf="false === clientChosen">
        <div class="uk-width-1-1">
          <label>Klient</label>
          <input autocomplete="off" [class.uk-form-danger]="chosenClientNameInput.touched && chosenClientNameInput.value == ''" (keyup)="searchClientsToChoose(chosenClientNameInput)" (keydown.enter)="chooseClientEnter()" type="text" name="chosenClientNameInput" [(ngModel)]="chosenClientName" class="uk-input" required id="chosenClientNameInput" #chosenClientNameInput>
          <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
            <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="chooseClientList.length > 0" [style.width]="chosenClientNameInput.clientWidth + 'px'">
              <li *ngFor="let client of chooseClientList" (click)="setChosenClient(client);" style="padding-left: 5px!important;">{{client.name}}</li>
            </ul>
          </div>
          <input type="hidden" autocomplete="none" [(ngModel)]="chosenClientId" class="uk-input">
        </div>
      </div>
      <form (ngSubmit)="verifyClientChoose()">
      <div class="uk-grid" *ngIf="true === clientChosen">
          <div class="uk-width-1-2">
            <label>kod pocztowy</label>
            <input type="text" class="uk-input" name="postal_code" [(ngModel)]="confirmClientForm.postal_code" #postalCodeInput>
          </div>
          <div class="uk-width-1-2">
            <label>number telefonu (bez prefixu)</label>
            <input type="text" class="uk-input" name="phone_number" [(ngModel)]="confirmClientForm.phone_number">
          </div>
          <div >
            <button type="submit" class="uk-button uk-button-primary uk-width-1-1 uk-margin-top">Potwierdź</button>
          </div>
          <div >
            <button class="uk-button uk-width-1-1 uk-margin-top" (click)="clientChosen = false ">Wróć</button>
          </div>
        </div>
      </form>


    </div>
  </div>
</div>
