import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ToastsService} from '../../../services/toasts.service';
import {ExportService} from '../../../services/export/export.service';
import {environment} from '../../../../environments/environment';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {UserModel} from '../../../models/user.model';
import {AuthService} from '../../../services/auth/auth.service';
import {UserRole} from '../../../enums/user-role';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

declare var UIkit: any;

@Component({
  selector: 'export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.scss']
})
export class ExportListComponent implements OnInit {
  @Input('type') type = null;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  public dtInit = false;
  dtTranslation = null;

  timeout = 100;
  invoker = '';
  exports: any = [];
  apiUrl = '';
  public userModel: UserModel = new UserModel();
  isOpen: any = null;
  lastEvent = null;

  protected readonly UserModel = UserModel;
  protected readonly Object = Object;

  lastEventProcessing = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastsService,
    public exportService: ExportService,
    private parcelService: ParcelsService,
    private http: HttpClient,
  ) {
    this.apiUrl = environment.api;
    this.dtTranslation = toastService.dtTranslation;
  }

  ngOnInit(): void {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;

      if (UserRole.warehouse_worker === this.userModel.role) {
        return;
      }

      this.showExportedFiles();
    });
  }

  showExportedFiles() {
    this.refreshDataOnly();

    if (this.lastEvent != null) {
      clearTimeout(this.lastEvent);
    }

    if (this.lastEventProcessing === true) {
      setTimeout(() => {
        this.showExportedFiles();
      }, 200);
      return;
    }

    this.lastEvent = setTimeout(() => {
      const that = this;
      let cl: any = [];

      if (this.userModel.role < 3) {
        cl = [
          {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
          {title: 'Klient', data: 'client_name', name: 'c.name'},
          {title: 'Nazwa', data: 'name', name: 'ef.name'},
          {title: 'Status', data: 'status', name: 'ef.status'}
        ];
      } else {
        cl = [
          {title: 'Data zlecenia', data: 'created_at', name: 'ef.created_at'},
          {title: 'Nazwa', data: 'name', name: 'ef.name'},
          {title: 'Status', data: 'status', name: 'ef.status'}
        ];
      }

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        processing: true,
        ordering: true,
        autoWidth: false,
        order: [
          [1, 'DESC']
        ],
        language: this.dtTranslation,
        columns: cl,
        ajax: (dataTablesParameters: any, callback) => {
          that.http.post(
            this.apiUrl + 'app/v1/export/latest/',
            {
              ...dataTablesParameters,
            }
          ).subscribe((resp: any) => {
            this.timeout = 900;
            that.exports = resp.data;
            that.dtInit = true;
            this.lastEvent = null;
            that.lastEventProcessing = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: [],
            });
          });
        }
      };

      if (true !== this.dtInit) {
        this.initializeDataTable();
        this.dtInit = true;
      }
    });
  }

  initializeDataTable(): void {
    setTimeout(() => {
      this.dtTrigger.next();
      this.dtInit = true;
    }, 100);
  }

  refreshDataOnly(): void {
    if (this.dtInit) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance
          .search('')
          .columns().search('')
          .draw();

        dtInstance.ajax.reload(() => {
        }, true);
      });
    }
  }

  download(ex) {
    if (ex.status === 'validated' || ex.status === 'validation_error' || ex.status === 'downloaded' || ex.status === 'generated') {
      this.parcelService.getPublicFileToken().subscribe(
        (tokenResponse: any) => {
          window.open(
            this.apiUrl
            + 'app/v1/export/download/'
            + ex.identifier
            + '?_public_token='
            + tokenResponse.public_token
          );

          UIkit.modal('#export-list-modal').hide();
        }
      );

      return;
    }

    this.toastService.showMessage('Pobranie pliku nie jest możliwe!', 'w');
  }

  markAsUsedToInvoice(file) {
    this.showConfirmMarkAsUsedToInvoiceModal(
      file.used_for_invoice
      ? 'Czy powtwierdzasz że zestawienie NIE zostało wykorzystane do faktury'
      : 'Czy powtwierdzasz że zestawienie zostało wykorzystane do faktury',
    ).then(
      confirmed => {
        if (confirmed) {
          this.exportService.markAsUsedForInvoice(
            file.id, !file.used_for_invoice,
          ).subscribe((response: any) => {
            file.used_for_invoice = !file.used_for_invoice;
          });
        }
      }
    );
  }

  async showConfirmMarkAsUsedToInvoiceModal(message): Promise<boolean> {
    return new Promise((resolve, reject) => {
      UIkit.modal.confirm(
        '<h3 style="color: #bd2130">' + message + '</h3>',
        {labels: {cancel: 'Nie', ok: 'Tak'}}
      ).then(() => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    })
  }

  handleRowClick(ex: any) {
    if (this.userModel.role === 3) {
      this.download(ex);
    }
    if (null === ex.errors_count) {
      this.download(ex);
    } else {
      this.exportService.findValidationErrorsByUuid(ex.identifier).subscribe(
        (response: any) => {
          ex.validation_errors = response;
          this.toggleAccordion(ex);
        }
      );
    }
  }

  toggleAccordion(item: any) {
    this.isOpen = this.isOpen === item ? null : item;
  }

  openParcel(error) {
    if (1 > error.parcelInternalNumbers.length) {
      return;
    }
    window.open('parcel/' + error.parcelInternalNumbers[0], '_blank');
  }

  reviewValidationError(error) {
    this.exportService.reviewValidationError(error.validationErrorId, error.reviewed).subscribe(
      (response: any) => {
        this.toastService.showMessage('Odznaczono poprawnie!', 's', 950);
      }
    );
  }
}

